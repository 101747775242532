import Layout from 'components/layout'
import Carousel, { HomepageSlide, Slide } from 'components/carousel'
import { HeroSecondary } from 'components/cards/hero'
import Background from 'components/cards/background'
import imgUrl from 'helpers/imgix'
import styles from './index.module.scss'
import fetchDatoCMS from 'lib/datocms'

type SlideImage = {
  size: string
  url: string
}

const Secondary = ({
  images,
  title,
}: {
  images: SlideImage[]
  title: string
}) => {
  const fallbackImg = images.find((img) => img.size === 'fallback')
  const imgOptions = images
    .filter((img) => img.size !== 'fallback')
    .map((img) => ({
      media: img.size,
      srcSet: img.url,
    }))
  return (
    <div className={styles['secondary']}>
      <HeroSecondary title={title}>
        <Background
          options={imgOptions}
          // @ts-ignore
          fallback={{ src: fallbackImg?.url }}
          lazy={true}
        />
      </HeroSecondary>
    </div>
  )
}

function Home({ images, secondary, slides }) {
  return (
    <Layout
      meta={{
        image: images['/social.jpg?w=1000&q=100'],
        description:
          'We make products to celebrate and explore the history of video games with the respect it deserves.',
      }}
      title="Analogue"
      footerBorderless={true}
    >
      <div
        id="Homepage"
        className={styles['homepage']}
        style={{ height: 'calc(100vh - var(--space-8x))' }}
      >
        <Carousel options={{ inFrame: true }}>
          {slides.map((slide, index) => (
            <HomepageSlide
              key={index}
              id={slide.id}
              title={slide.title}
              images={slide.images}
              preload={index === 0}
              url={slide.url}
              color={slide.color?.hex}
            />
          ))}
        </Carousel>
      </div>
      <Secondary title={secondary.title} images={secondary.images} />
    </Layout>
  )
}

Home.theme = 'dark'
export default Home

export async function getStaticProps({ preview }: { preview: boolean }) {
  const query = `
    query {
      homepage {
        slides {
          id: slideId
          title
          url
          color {
            hex
          }
          images {
            size
            url
          }
        }
        secondaryImages {
          size
          url
        }
        heading
      }
    }`

  const { data } = await fetchDatoCMS(query, {}, { preview })
  const slides = data.homepage.slides.map((slide) => {
    const id = slide.id || null
    const title = slide.title || null
    return {
      ...slide,
      id,
      title,
    }
  })
  const secondary = {
    title: data.homepage.heading,
    images: data.homepage.secondaryImages,
  }
  const imgPaths = ['/social.jpg?w=1000&q=100']
  const images = imgPaths.reduce((list, img) => {
    list[img] = imgUrl(img)
    return list
  }, {})

  return {
    props: { images, secondary, slides },
  }
}
